html {
  height: 100%;
}
.body {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  margin: 0;
  padding: 0;
  min-height: 100%;
}
.container {
  text-align: center;
}

h1 {
  margin-bottom: 0px;
  margin-top: 10px;
}

/* Calculator styles */
.label {
  font-size: 18px;
  line-height: 24px;
}

.helper {
  font-size: 10px;
  line-height: 14px;
  font-style: italic;
}

.normalText {
  font-size: 12px;
  line-height: 20px;
}

.clearButton {
  font-size: 12px;
  line-height: 14px;
  text-align: "right";
  padding: 12;
  border: none;
  background: none;
  min-width: 80px;
  padding: 10px;
  font-family: "JetBrains Mono";
}
.clearButton:hover {
  border: 1px #a9a9a9 solid;
  border-radius: 3px;
  background-color: #f2f2f2;
  font-weight: bold;
}
.clearButton:focus {
  background-color: #f2f2f2;
  font-weight: bold;
}

.inputGroup {
  text-align: "left";
  display: inline-flex;
  align-items: center;
}

.inputRow {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

input {
  border: 1px solid lightgray;
  border-radius: 3px;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  background-color: none;
  font-family: "JetBrains Mono";
}

input:focus {
  font-weight: bold;
}

/* Results styles */
table {
  width: 100%;
  border-collapse: collapse;
}

th {
  font-size: 14px;
  line-height: 30px;
  top: 0;
  position: sticky;
  text-align: left;
}

th:last-of-type {
  text-align: right;
}

tr {
  font-size: 18;
  line-height: 40px;
}

tr:hover {
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
  color: black;
}

.footer {
  font-size: 12px;
  line-height: 20px;
}

a:hover {
  font-weight: bold;
}

a:focus {
  font-weight: bold;
}

.tableWrapper {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 400px);
  min-height: 200px;
  width: 100%;
  /* Hide scrollbar */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tableWrapper::-webkit-scrollbar {
  display: none;
}

.tableWrapper td:first-of-type,
th:first-of-type {
  padding-left: 10px;
}

.tableWrapper td:last-of-type,
th:last-of-type {
  text-align: right;
  padding-right: 10px;
}
